;((global) => {
  var addEvent, changes
  if (document.addEventListener) {
    addEvent = function (element, eventName, callback) {
      return element.addEventListener(eventName, callback, false)
    }
  } else {
    if (document.attachEvent) {
      addEvent = function (element, eventName, callback) {
        element.attachEvent('on' + eventName, callback)
      }
    } else {
      addEvent = function (element, eventName, callback) {
        element['on' + eventName] = callback
      }
    }
  }
  global.flowtype = function (element, options) {
    changes = function (el) {
      var elw = el.clientWidth
      var width = elw > options.maximum ? options.maximum : elw < options.minimum ? options.minimum : elw
      var fontBase = width / options.fontRatio
      var fontSize = fontBase > options.maxFont ? options.maxFont : fontBase < options.minFont ? options.minFont : fontBase; el.style.fontSize = fontSize + 'px'
    }
    element.updateFlowType = function () {
      changes(element)
    }
    addEvent(global, 'resize', element.updateFlowType)
    element.updateFlowType()
  }
})(window)
